import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../components/layout"
import Seo from "../../../components/seo"

const Management = () => {
    return (
        <Layout>
            <Seo title="Management" />
            <div className="container py-3 py-lg-4">
                <div className="row g-0 mb-3">
                    <div className="col border-bottom border-1 border-dark">
                        <h1>Mangement</h1>
                    </div>
                </div>

                <div className="row row-cols-1 row-cols-sm-2 row-cols-lg-3 row-cols-xxl-3 row-cols-xxl-5 g-3 mb-3">
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage className="card-img-top " src="../../../images/management/muditpaliwal.png" alt="paliwal" />
                            <div className="card-body">
                                <h5 className="card-title">Mudit Paliwal</h5>
                                <p className="card-text">Founder & CEO</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage className="card-img-top " src="../../../images/management/buljo.jpg" alt="paliwal" />
                            <div className="card-body">
                                <h5 className="card-title">Endre Buljo</h5>
                                <p className="card-text">Executive VP Tonnage</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage className="card-img-top " src="../../../images/management/jeremiassen.jpg" alt="paliwal" />
                            <div className="card-body">
                                <h5 className="card-title">Henrik Jeremiassen</h5>
                                <p className="card-text">Executive VP Compliance</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage className="card-img-top " src="../../../images/management/andrewbenjamin.jpg" alt="paliwal" />
                            <div className="card-body">
                                <h5 className="card-title">Andrew Benjamin</h5>
                                <p className="card-text">Executive VP Risk</p>
                            </div>
                        </div>
                    </div>
                    <div className="col">
                        <div className="card h-100">
                            <StaticImage className="card-img-top " src="../../../images/management/das.jpg" alt="paliwal" />
                            <div className="card-body">
                                <h5 className="card-title">Capt. Sumanta Das</h5>
                                <p className="card-text">Executive VP Operations</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ">
                    <div className="col">
                        <div className="card-body h-100 bg-dark ">
                            <blockquote className="blockquote text-light mb-0">
                                <p>"Leading through action, experience & dedication."</p>
                            </blockquote>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Management
